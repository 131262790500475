import React from 'react'

import * as BaseSearch from '@arch-log/webapp.shared/KeywordSearch'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { ArchLogWithIcon as LogoImage } from '@arch-log/webapp.shared/Logo/Image'
import { ArchLogWithIconInProject as LogoImageInProject } from '@arch-log/webapp.shared/Logo/Image'

import * as Links from '@arch-log/webapp.shared/Links'
import * as BaseMenu from './Menu'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Menu = BaseMenu.Default

/**
 */
export const LogoutMenu = BaseMenu.Logout

/**
 */
export const Logo = withDefaultRenderComponent(
  ({ canNavigate = true, components: { Link: LinkTo = Links.ToTop } }) => {
    const LinkForLogo = canNavigate ? LinkTo : Links.Noop

    return (
      <LinkForLogo>
        <LogoImage className={styles.Logo_Image} alt="Arch-LOG" />
      </LinkForLogo>
    )
  },
)

/**
 */
export const LogoInProject = withDefaultRenderComponent(
  ({ canNavigate = true, components: { Link: LinkTo = Links.ToTop } }) => {
    const LinkForLogo = canNavigate ? LinkTo : Links.Noop

    return (
      <>
        <LinkForLogo>
          <LogoImageInProject className={styles.Logo_Image} alt="Arch-LOG" />
        </LinkForLogo>
      </>
    )
  },
)

/**
 */
export const SignUp = withDefaultRenderComponent(
  ({ components: { Link = Links.ToRegistration } = {} }) => (
    <Link className={styles.LinkToSignUp}>
      <Literals.SignUp />
    </Link>
  ),
)

/**
 */
export const SignIn = withDefaultRenderComponent(
  ({ components: { Link = Links.ToLogin } = {} }) => (
    <Link className={styles.LinkToSignIn}>
      <Literals.SignIn />
      <Styled.LoginIcon />
    </Link>
  ),
)

/**
 */
export const ToProject = () => (
  <Links.Project.ToTop className={styles.LinkToProject}>
    <Styled.ProjectIcon />
    <Styled.ProjectText>
      <Literals.Project />
    </Styled.ProjectText>
  </Links.Project.ToTop>
)

/**
 * Search Container
 */
export const Search = ({ children, isEnabled, ...props }) => {
  if (isEnabled) {
    return <BaseSearch.Default {...props}>{children}</BaseSearch.Default>
  }

  return null
}

/**
 */
export const ToHowTo = () => (
  <Links.ToHowTo className={styles.LinkToHowTo}>
    <Styled.HowToIcon />
  </Links.ToHowTo>
)